// const { default: Footerless } = require('./layouts/footerless.vue')

module.exports = {
    theme: {
        aspectRatio: {
            square: '1 / 1',
            auto: 'auto',
            1.33: '1.33',
            1: '1',
            2: '2',
            3: '3',
            4: '4',
        },
        extend: {
            colors: {
                white: '#ffffff',
                black: '#000000',
                link: '#B56576',
                transparent: 'transparent',
                current: 'currentColor',
                DEFAULT: 'currentColor',
                nude: {
                    25: '#faf5f0',
                    50: '#FBF9F6',
                    200: '#F4EDE9',
                    300: '#ded1c3',
                    900: '#1f1509',
                    // v2
                    1: '#F4EAE0',
                    2: '#faf5f0',
                },
                dustyrose: {
                    500: '#B56576',
                    900: '#B56576',
                },
                dapperblue: {
                    700: '#5f6870',
                },
                primary: {
                    DEFAULT: '#1f1509', // nude-900
                },
                green: {
                    success: '#15b342',
                },
                red: {
                    error: '#ff3820',
                },
                gray: {
                    0: '#EBE6E1',
                    1: '#C6C9CC',
                    2: '#313131',
                    3: '#5F6870',
                    // v2
                    4: '#696867',
                    5: '#737373',
                    6: '#868686',
                    7: '#373737',
                },
                // v2
                background: '#FBF9F7',
                general: {
                    1: '#7D7C7B',
                    2: '#f0edeb',
                    3: '#E4E2DE',
                    4: '#8d8780', // #1F1509 - 50%
                    5: '#1F1509',
                    6: '#5B5B5F',
                    7: '#AE988A',
                    8: '#FBF9F7',
                    9: '#323232',
                    10: '#EEECE9',
                    11: '#3E1B00',
                    12: '#E6E3E1',
                    13: '#403935',
                    14: '#EBE9E9',
                },
                accent: {
                    1: '#B56477',
                    2: '#B56576',
                    3: '#e7dee7',
                    4: '#B3652D',
                },
                alert: {
                    1: {
                        background: '#FFF0D8',
                        text: '#574c3d',
                    },
                    2: {
                        background: '#FDE5FF',
                        text: ' #6A1070',
                    },
                    3: {
                        background: '#D7EBFD',
                        text: ' #183767',
                    },
                    4: {
                        background: '#FFF2F0',
                        text: ' #FF3820',
                    },
                },
                danger: {
                    1: '#FFE7E4',
                    2: '#FF3820',
                    3: '#C64308',
                    4: '#FFF1F0',
                    5: '#FFF3F1',
                },
                warning: {
                    1: '#FDF6EA',
                    2: '#8F611C',
                },
                lineHeight: {
                    'none-i': 'none !important',
                },
            },
            borderWidth: {
                6: '6px',
            },
            boxShadow: {
                primary: '0 0 0 2px #FFB2D5',
                low: '0 4px 16px 0 rgba(31,21,9,0.12)',
                high: '0 4px 32px 0 rgba(31,21,9,0.2)',
                'slide-top': '0px -6px 12px 0px #00000014',
            },

            fontFamily: {
                sans: ['proxima-nova', 'system-ui', '-apple-system'],
                serif: ['juana', 'serif', 'Georgia', 'Cambria'],
            },
            fontSize: {
                10: '0.625rem',
                11: '0.688rem',
                12: '0.75rem',
                13: '0.813rem',
                14: '0.875rem',
                15: '0.938rem',
                'base-i': '1rem !important',
                22: '1.375rem',
                24: '1.5rem',
                28: '1.75rem',
                xs: '0.8125rem',
                sm: '0.9375rem',
                lg: '1.125rem',
                xl: '1.25rem',
                '2xl': '2.125rem',
                '3xl': '2.5rem',
                '4xl': '3.25rem',
            },
            letterSpacing: {
                tighter: '-0.025rem',
                tight: '-0.005rem',
                normal: '0',
                wide: '0.005rem',
                wider: '0.015rem',
                widest: '0.075rem',
            },
            screens: {
                // to be revised
                'xs-max': { max: '575px' },
                sm: '576px',
                'sm-max': { max: '767px' },
                md: '768px',
                'md-max': { max: '991px' },
                'sm-d-max': { max: '991px' },
                'lg-d': '992px',
                lg: '992px',
                'lg-max': { max: '1379px' },
                xl: '1380px',
                'xl-max': { max: '1535px' },
                '2xl': '1536px',
                '2xl-max': { max: '1999px' },
                '3xl': '2000px',
                'm-max': { max: '991px' }, // mobile max
                'd-min': '992px', // desktop min
            },
            lineHeight: {
                normal: '1.5',
                snug: '1.375',
                tight: '1.15',
                none: '1',
                'none-i': '1 !important',
                h: '1.15',
            },
            opacity: {
                15: '0.15',
                25: '0.25',
                75: '0.75',
                '50-i': '0.5 !important',
                '100-i': '1 !important',
            },
            spacing: {
                '0-i': '0 !important',
                7.5: '1.875rem',
                18: '4.5rem',
                header: 'var(--header-height)',
            },
            width: {
                150: '37.5rem',
                screen: '100svw',
            },
            height: {
                'auto-i': 'auto !important',
                fit: 'fit-content',
                inherit: 'inherit',
                4.5: '1.125rem',
                25: '6.25rem',
                98: '28rem',
                150: '35rem',
                '70vh': '70vh',
                // v2
                '2px': '2px',
                'questionnaire-header': 'var(--questionnaire-header-height)',
                'questionnaire-dense-header': 'var(--questionnaire-dense-header-height)',
                header: 'var(--header-height)',
                footer: 'var(--footer-height)',
                screen: '100svh',
            },
            maxWidth: {
                48: '12rem',
                'screen-3xl': '2000px',
                '3xl': '52rem',
                half: '50%',
            },
            maxHeight: {
                '4/5': '80%',
            },
            minHeight: {
                150: '35rem',
                // v2
                'questionnaire-content': 'calc(100svh - var(--questionnaire-header-height))',
                'questionnaire-content-dense': 'calc(100svh - var(--questionnaire-dense-header-height))',
                screen: '100dvh',
                content: 'calc(100svh - var(--header-height))',
                'item-content': '85px',
                '1/3': `${100 / 3}%`,
            },
            gridTemplateColumns: {
                'auto-1': 'auto 1fr',
                '1-3': '1fr 3fr',
                '1-4': '1fr 4fr',
                '3-1': '3fr 1fr',
                '1-2-1': '1fr 2fr 1fr',
                '1-4-1': '1fr 4fr 1fr',
                ...Array.from([14, 16, 18, 20]).reduce((p, c) => {
                    p[c] = `repeat(${c}, minmax(0, 1fr))`
                    return p
                }, {}),
            },
            // v2
            inset: {
                header: 'var(--header-height)',
            },
            translate: {
                '0-i': '0 !important',
            },
            flex: {
                '1/2': '1 1 50%',
                full: '1 1 100%',
            },
        },
    },
    important: false,
    variants: {},
    corePlugins: {
        container: false,
        aspectRatio: false,
    },
    plugins: [
        require('@tailwindcss/aspect-ratio'),
        function ({ addComponents }) {
            addComponents({
                '.container': {
                    maxWidth: '100%',
                    padding: '0 1rem',
                    '@media screen(sm)': {
                        maxWidth: '100%',
                    },
                    '@media screen(md)': {
                        maxWidth: '100%',
                    },
                    '@media screen(lg)': {
                        maxWidth: '900px',
                        padding: '0 0',
                    },
                    '@media screen(xl)': {
                        maxWidth: '1200px',
                    },
                    '@media screen(2xl)': {
                        maxWidth: '1208px',
                    },
                },
                '.container-half': {
                    maxWidth: '50%',
                    '@media screen(sm)': {
                        maxWidth: '50%',
                    },
                    '@media screen(md)': {
                        maxWidth: '50%',
                    },
                    '@media screen(lg)': {
                        maxWidth: '450px',
                        padding: '0 0',
                    },
                    '@media screen(xl)': {
                        maxWidth: '600px',
                    },
                    '@media screen(2xl)': {
                        maxWidth: '604px',
                    },
                },
                '.user-layout-container': {
                    padding: '0 1rem',
                    '@media screen(sm)': {
                        maxWidth: '100%',
                    },
                    '@media screen(md)': {
                        maxWidth: '100%',
                    },
                    '@media screen(lg)': {
                        maxWidth: '900px',
                        padding: '0 0',
                    },
                    '@media screen(xl)': {
                        maxWidth: '1200px',
                    },
                    '@media screen(2xl)': {
                        maxWidth: '844px',
                    },
                },
            })
        },
    ],
    safelist: [
        ...Array.from([...Array.from({ length: 10 }, (_, i) => i + 1), ...Array.from(['1-3', '1-4', '3-1', '1-2-1', '1-4-1', '14', '16', '18', '20'])]).reduce(
            (p, c) => {
                p.push(`grid-cols-${c}`)
                p.push(`sm:grid-cols-${c}`)
                p.push(`md:grid-cols-${c}`)
                p.push(`lg:grid-cols-${c}`)
                return p
            },
            []
        ),
        ...Array.from({ length: 6 }, (_, i) => i + 1).reduce((p, c) => {
            p.push(`gap-${c}`)
            return p
        }, []),
        ...Array.from({ length: 3 }, (_, i) => i + 1).reduce((p, c) => {
            p.push(`alert-${c}-backgorund`)
            p.push(`alert-${c}-text`)
            return p
        }, []),
        ...Array.from(['start', 'end', 'center', 'baseline', 'stretch']).reduce((p, c) => {
            p.push(`items-${c}`)
            return p
        }, []),
        ...['multiselect'],
        ...Array.from({ length: 10 }, (_, i) => i + 1).reduce((p, c) => {
            p.push(`h${c}-v2`)
            return p
        }, []),
        ...Array.from({ length: 6 }, (_, i) => i).reduce((p, c) => {
            p.push(`bg-opacity-${c}0`)
            return p
        }, []),
    ],
    content: [
        'components/**/*.vue',
        'layouts/**/*.vue',
        'pages/**/*.vue',
        'plugins/**/*.js',
        'nuxt.config.js',
        // TypeScript
        'plugins/**/*.ts',
        'nuxt.config.ts',
    ],
}
